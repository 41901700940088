.mainadmin {
  display: flex;
  align-items: start;
  background-color: #F2FAFE;
}

.mainright {
  margin-left: 20px;
  width: 100%;
}

.pagehead {
  background-color: #fff;
  padding: 10px 30px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.pagehead h2 {
  color: #06101C;
  font-size: 18px;
  font-weight: 600;
}

.pagehead h2 span {
  color: #EE2B2E;
  font-size: 18px;
  font-weight: 600;
}

.formadmin {
  padding: 30px 30px;
  background-color: #fff;
  width: 100%;
  margin-top: 30px;
}

.formadmin h2 {
  color: #EE2B2E;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

input {
  box-sizing: border-box;
  width: 100%;
  padding: 0.25em 0.5em;
  margin: 0.25em 0 1em;
  border: 1px solid #D9D9D9;
  border-radius: 0.25em;
  height: 45px;
  max-width: 900px;
  width: 100%;
  padding-left: 15px;
}

 input [type="file"] { 
  border: none;
 }
 textarea  {
  box-sizing: border-box;
  width: 100%;
  padding: 0.25em 0.5em;
  margin: 0.25em 0 1em;
  border: 1px solid #D9D9D9;
  border-radius: 0.25em;
  height: auto;
  max-width: 900px;
  width: 100%;
  padding-left: 15px;
  min-height: 200px;
}

label {
  width: 100%;
  display: block;
  margin: 10px 0px;
}

.submitbtn {
  background-color: #3A4096;
  padding: 10px 30px;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
}
.logoutbtn {
  background-color: #3A4096;
  padding: 6px 15px;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: 50px;
}

select {
  box-sizing: border-box;
  width: 100%;
  padding: 0.25em 0.5em;
  margin: 0.25em 0 1em;
  border: 1px solid #D9D9D9;
  border-radius: 0.25em;
  height: 45px;
  max-width: 900px;
  width: 100%;
  padding-left: 15px;

}

.submitbtn {
  background-color: #1e2264;
  margin-top: 15px;
}

.productsdiv { padding-top: 30px;}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 800px !important;
}

.logindiv { max-width: 800px; margin: auto;}

.floatright { float: right;}

.editegallery { margin-top: 30px;}
.gallerythumb { border: 1px solid #ddd; padding: 10px;}

.gallerythumb img{ width: 100%; max-width: 100%; height:auto;}
.deletebtn { background-color: transparent; border: 0px; margin-top: 10px; display: block; text-align: left;}
.deletebtn img { width: 20px; filter: brightness(0) invert(1);} 

.edite_btn { background-color: transparent; border: 0px; margin-top: 10px; display: block; text-align: left; margin-left: 5px;}
.edite_btn img { width: 20px; filter: brightness(0) invert(1);} 


.servicesthumb { margin-top: 40px; }

.servicesthumb_item {padding-bottom: 15px; padding-top: 15px; border-bottom: 1px solid #a8a8a8;}
.servicethumb img{ width: 100%; max-width: 100%; height:auto;}

.btn-primary, .btn{ background-color: rgb(5, 5, 128) !important; color: #fff;}

.tables { margin-top: 40px;}
.prothumbimg img { width: 70px;}