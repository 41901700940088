.sidemenu {
    background-color: #fff;
    width: 400px;
    padding: 20px;
    border-right: 2px solid #ddd;
    height: 100vh;
}

.logo {
    padding: 10px;
    text-align: left;
}

.sideitem li {
    list-style: none;
    text-align: left;    
    color: #222222;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    display: block;
    margin-bottom: 5px;
}

.sideitem li a {
    text-decoration: none;
    list-style: none;
    text-align: left;
    padding: 10px 15px;
    color: #222222;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    display: block;
}

.sideitem { padding-top: 20px;}



.sideitem li.active {
    background-color: rgb(5, 5, 128);
    color: #fff;
    text-decoration: none;
    list-style: none;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    display: block;
    border-radius: 10px;
}

.sideitem li.active a {
    color: #fff;
}